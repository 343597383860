import React from 'react';
import md5 from 'crypto-js/md5';

// Component to display user's image
const UserProfileImage = ({ user }) => {
  const defaultImage = '/assets/imgs/profile.jpg';

  // Function to generate Gravatar URL
  const getGravatarUrl = (email) => {
    const hash = md5(email.trim().toLowerCase()).toString();
    return `https://www.gravatar.com/avatar/${hash}?d=https://app.chadgpt.com/assets/imgs/profile.jpg`;
  };

  // Determine which image source to use
  const imageUrl = user?.data.photoURL
    ? user.data.photoURL
    : user?.data.email
      ? getGravatarUrl(user.data.email)
      : defaultImage;

  return (
    <div className="w-8 rounded-full">
      <img src={imageUrl} alt="Profile" />
    </div>
  );
};

export default UserProfileImage;
