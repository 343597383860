import React, { useEffect } from 'react';
import SubscriptionCard from '../components/SubscriptionCard';
import { useSelector, useDispatch } from 'react-redux';
import { logout, reset } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const SubscriptionPlans = () => {
  const { planSelected } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (planSelected) {
      window.location.href = '/dashboard';
    }
  }, [planSelected]);

  const handleLogout = () => {
    dispatch(reset());
    dispatch(logout());
    navigate('/');
  };

  const plansData = [
    {
      stripeId: process.env.REACT_APP_YEAR_PRICE_ID,
      paypalId: process.env.REACT_APP_PAYPAL_YEARLY_PLAN_ID,
      name: 'Pro Yearly',
      price: 8.24,
      type: 'year',
      points: [
        '2-Months Free on Pro Yearly.',
        'No Ads Ever',
        'Unlimited Access',
        '10+ Advanced AI Models',
        'Powerful AI Assistant',
        'Advanced AI Image Generator',
        'Deep Research',
      ],
    },
    {
      stripeId: process.env.REACT_APP_MONTH_PRICE_ID,
      paypalId: process.env.REACT_APP_PAYPAL_MONTHLY_PLAN_ID,
      name: 'Pro Monthly',
      price: 9.97,
      type: 'month',
      points: [
        'No Ads Ever',
        'Unlimited Access',
        '10+ Advanced AI Models',
        'Powerful AI Assistant',
        'Advanced AI Image Generator',
        'Deep Research',
      ],
    },
  ];

  return (
    <div
      className="flex flex-col justify-center items-center bg-center w-full relative"
      style={{
        background: '#1D232A',
        minHeight: '100vh',
      }}
    >
      <button
        onClick={handleLogout}
        className="absolute top-4 right-4 bg-primary text-white px-4 py-2 rounded-md hover:bg-charcoal transition-colors"
      >
        Logout
      </button>

      <h1 className="text-3xl font-regular text-white mt-10 mb-5">
        Chad<span className="font-extrabold text-primary">GPT</span>
      </h1>
      <div className="flex flex-col gap-6 text-center">
        <h2 className="text-white text-4xl md:text-5xl font-medium">Select Plan</h2>
        <p className="text-lg">Please select a plan to Continue</p>
      </div>
      <div className="flex justify-center flex-wrap gap-14 my-10">
        {plansData.map((plan, index) => (
          <SubscriptionCard
            key={index}
            name={plan.name}
            price={plan.price}
            type={plan.type}
            points={plan.points}
            stripeId={plan.stripeId}
            paypalId={plan.paypalId}
          />
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlans;
