import React, { useState, useEffect } from 'react';
// Unused imports are commented out. Reintroduce as needed.
// import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// Unused for now
// import Turnstile, { useTurnstile } from "react-turnstile";

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Unused imports are commented out. Reintroduce as needed.
// import { login, getMe } from "../features/auth/authSlice";
// import { getUserHistory } from "../features/chats/chatSlice";
// import {
//   getSystemPrompts,
//   getUserPrompts,
// } from "../features/prompts/promptSlice";

import axios from 'axios';
import { getMe, login } from '../features/auth/authSlice';

const VerificationEmailPageData = () => {
  const [text, setText] = useState('Verifying Your Email');
  const { loginInfo, planSelected } = useSelector((state) => state.auth);

  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const EmailVerificationSend = async () => {
    try {
      // Verify email
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/verify-email/${id}`);

      setText('Your email has been verified successfully.');
      setVerificationSuccess(true);

      // Check if loginInfo exists before dispatching
      if (!loginInfo?.token) {
        localStorage.clear();
        navigate('/');
        console.error('No login token available');
        return;
      }

      dispatch(getMe(loginInfo.token));

      // Update localStorage
      try {
        const userData = localStorage.getItem('loginInfo');
        if (!userData) {
          console.error('No user data found in localStorage');
          return;
        }

        const user = JSON.parse(userData);
        if (user?.user) {
          user.user.isEmailVerified = true;
          localStorage.setItem('loginInfo', JSON.stringify(user));
        }
      } catch (storageError) {
        console.error('Error updating localStorage:', storageError);
      }

      // Track event
      ReactGA.event({
        category: 'email_verification',
        action: 'email_verified',
      });
    } catch (error) {
      console.error('Email verification failed:', error);
      setText('An error occurred while verifying your email. Please try again.');
      setVerificationSuccess(false);
    }
  };

  useEffect(() => {
    EmailVerificationSend();
  }, []);

  // Function to handle navigation to dashboard
  const handleContinue = () => {
    //localStorage.clear();
    navigate('/dashboard');
  };

  return (
    <section
      className="bg-dark flex flex-col justify-center items-center h-screen"
      style={{
        backgroundImage: 'url(/assets/imgs/authBack.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl md:text-5xl font-semibold text-white text-center">
          Welcome to
          <span
            className="border-s-4 border-primary"
            style={{
              background:
                'linear-gradient(90deg, rgba(32, 253, 201, 0.50) 0%, rgba(32, 253, 201, 0.00) 100%)',
            }}
          >
            ChadGPT
          </span>
        </h1>

        <div className="mt-5 text-2xl md:text-2xl font-semibold text-white text-center ">
          <h4>{text}</h4>
          {verificationSuccess ? (
            <button
              className="my-3 w-96 p-4 bg-primary text-dark rounded-md font-semibold text-lg text-white"
              onClick={handleContinue}
            >
              Continue
            </button>
          ) : (
            <button
              className="my-3 w-96 p-4 bg-primary text-dark rounded-md font-semibold text-lg"
              onClick={() => navigate('/email-verify')}
            >
              Retry Verification
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default VerificationEmailPageData;
