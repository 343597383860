import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, reset } from '../features/auth/authSlice';

import {
  ChevronUp,
  FolderOpen,
  HelpCircle,
  LogOut,
  MessageCircleQuestion,
  Receipt,
  Tags,
} from 'lucide-react';
import PricingModal from './modals/PricingModal';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import UserProfileImage from './UserProfileImage';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ProfileButton = ({ open, handleToggle, themeChecked }) => {
  const { user, loginInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPricingModel, setShowPricingModel] = useState(false);

  const handleLogout = () => {
    navigate('/');
    dispatch(reset());
    dispatch(logout());
  };

  const handleSupportClick = () => {
    const sbMainElement = document.querySelector('.sb-chat-launcher-iframe');

    const sbWidget = document.querySelector('.sb-chat-widget-iframe');

    if (sbWidget) {
      // Remove 'is-hidden' class and add 'is-visible' class
      sbWidget.classList.remove('is-hidden');
      sbWidget.classList.remove('sb-hidden');

      sbWidget.classList.add('is-visible');

      sbMainElement.style.display = 'block';
    }
  };

  const openBillingDashboard = async () => {
    try {
      const response = await axios.get(`/api/v1/stripe/customer-portal`, {
        headers: {
          Authorization: `Bearer ${loginInfo.token}`,
        },
      });
      const url = response.data.url;
      window.open(url, '_blank');
    } catch (error) {
      // Log the error for debugging purposes
      console.error('Error opening billing dashboard:', error);

      // Display a toast notification to the user
      toast.error('Error Opening Billing Dashboard', {
        hideProgressBar: true,
        draggable: true,
      });
    }
  };

  const storedTheme = localStorage.getItem('theme') || 'dark';

  const textColorClass = storedTheme === 'dark' ? 'text-white' : 'text-black';

  return (
    <div
      className={`my-3 rounded-full flex items-center ${
        open ? 'justify-between p-1 bg-dark' : 'flex-col gap-7'
      }`}
    >
      <div className={`flex justify-between transform -rotate-90 ${!open ? 'block' : 'hidden'}`}>
        <label className="cursor-pointer grid place-items-center">
          <input
            type="checkbox"
            onChange={handleToggle}
            checked={themeChecked}
            value="synthwave"
            className="toggle theme-controller bg-base-content row-start-1 col-start-1 col-span-2 border-0"
          />
          <svg
            className="col-start-1 row-start-1 stroke-base-100 fill-base-100"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="5" />
            <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
          </svg>
          <svg
            className="col-start-2 row-start-1 stroke-base-100 fill-base-100"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
          </svg>
        </label>
      </div>

      <div className="dropdown dropdown-bottom dropdown-center text-white">
        <div
          tabIndex={0}
          role="button"
          className="btn bg-transparent hover:bg-transparent border-none flex flex-col w-full min-w-0"
        >
          <div className="avatar flex items-center gap-2">
            <UserProfileImage user={user} />
            {/* <div className="w-8 rounded-full">
              <img
                src={
                  user?.data.photoURL
                    ? user?.data.photoURL
                    : "/assets/imgs/profile.jpg"
                }
              />
            </div> */}
          </div>
          <p
            className={`text-white text-sm ${
              open ? 'block' : 'hidden'
            } max-w-[60%] whitespace-nowrap overflow-hidden overflow-ellipsis`}
          >
            {user?.data.displayName}
          </p>
          <ChevronUp className={`${open ? 'block' : 'hidden'} text-white`} />
        </div>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow rounded-box w-52 bg-dark "
        >
          <li
            className="text-color font-semibold hover:bg-charcoal"
            onClick={() => setShowPricingModel(true)}
          >
            <div className="flex justify-start items-center gap-1">
              <Tags className="text-white" size={15} />
              <p className="text-white">{!user?.data.proAccount ? 'Upgrade to Pro' : 'Pricing'}</p>
            </div>
          </li>
          <li className="text-color font-semibold hover:bg-charcoal">
            <a
              href="https://help.chadgpt.com/"
              className="flex justify-start items-center gap-1"
              style={{ textDecoration: 'none' }}
            >
              <FolderOpen className="text-white" size={15} />
              <p className="text-white">Resources</p>
            </a>
          </li>

          {/* <li className="text-color font-semibold hover:bg-charcoal" onClick={handleSupportClick}> */}

          <li className="text-white font-semibold hover:bg-charcoal " onClick={handleSupportClick}>
            <div className="flex justify-start items-center gap-1  ">
              <HelpCircle className="text-white" size={15} /> <p className="text-white">Support</p>
            </div>
          </li>
          <li
            className="text-color font-semibold hover:bg-charcoal"
            data-productlift-sidebar="c253dd5b-7e2f-4efb-b11d-7c847b96d621"
          >
            <div className="flex justify-start items-center gap-1">
              <MessageCircleQuestion className="text-white" size={15} />
              <p className="text-white">Feedback</p>
            </div>
          </li>
          {user?.data.proAccount && (
            <li
              className="text-color font-semibold hover:bg-charcoal"
              onClick={openBillingDashboard}
            >
              <div className="flex justify-start items-center gap-1">
                <Receipt className="text-white" size={15} />
                <p className="text-white">Billing Dashboard</p>
              </div>
            </li>
          )}
          <li className="text-color font-semibold hover:bg-charcoal">
            <button className="flex justify-start items-center gap-1" onClick={handleLogout}>
              <LogOut className="text-white" size={15} />
              <p className="text-white">Logout</p>
            </button>
          </li>
        </ul>
      </div>
      {showPricingModel && (
        <>
          <Elements stripe={stripePromise}>
            <PricingModal open={showPricingModel} setOpen={setShowPricingModel} />
          </Elements>
        </>
      )}
    </div>
  );
};

export default ProfileButton;
