import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';

const MobileNavbar = () => {
  const [isLeftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const [isRightSidebarOpen, setRightSidebarOpen] = useState(false);

  const toggleLeftSidebar = () => {
    setLeftSidebarOpen(!isLeftSidebarOpen);
    setRightSidebarOpen(false);
  };

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!isRightSidebarOpen);
    setLeftSidebarOpen(false);
  };

  const closeSidebar = () => {
    setLeftSidebarOpen(false);
    setRightSidebarOpen(false);
  };

  return (
    <div className="lg:hidden">
      <div className="flex justify-between relative">
        {/* Left hamburger icon */}
        <div className="flex items-center justify-start p-3">
          <Menu size={32} className="cursor-pointer text-textColor" onClick={toggleLeftSidebar} />
        </div>

        {/* Right hamburger icon */}
        <div className="flex items-center justify-end p-3">
          <Menu size={32} className="cursor-pointer text-textColor" onClick={toggleRightSidebar} />
        </div>
      </div>

      {/* Overlay for options */}
      {(isLeftSidebarOpen || isRightSidebarOpen) && (
        <>
          <div
            className="absolute top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 z-10"
            onClick={closeSidebar}
          >
            <div
              className={`flex items-center justify-${isRightSidebarOpen ? 'between' : 'end'} p-4`}
            >
              <X size={32} className="cursor-pointer text-white" onClick={closeSidebar} />
            </div>
          </div>
          <div>
            {isLeftSidebarOpen && (
              <div className="absolute z-50 h-full top-4">
                <LeftSidebar collapsible={false} toggleSidebar={toggleLeftSidebar} />
              </div>
            )}
            {isRightSidebarOpen && (
              <div className="absolute z-50 h-full right-0 top-4">
                <RightSidebar collapsible={false} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MobileNavbar;
