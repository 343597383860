import React, { useRef, useEffect } from 'react';
import { useAppContext } from '../AppContext';
import { useState } from 'react';
import FileUploadContainer from './FileUploadContainer';
import Upload from './Upload';
import FunctionalModelSelector from './FunctionalModelSelector';
import { Camera, ArrowUp } from 'lucide-react';

const PromptInput = ({ handleSubmit }) => {
  const { inputValue, updateInputValue, gptModel } = useAppContext();
  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = 'auto';
    const numberOfLines = textarea.value.split('\n').length;
    const maxHeight = 10;

    if (numberOfLines <= maxHeight) {
      textarea.style.overflowY = 'hidden';
      textarea.style.height = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.overflowY = 'scroll';
      textarea.style.height = `${20 * maxHeight}px`;
    }
  };

  const handleChange = (e) => {
    updateInputValue(e.target.value);
    adjustTextareaHeight();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default action of the Enter key
      if (e.shiftKey) {
        // Shift+Enter, add new line
        const newValue = `${inputValue}\n`;
        updateInputValue(newValue);
        setTimeout(adjustTextareaHeight, 0);
      } else {
        // Enter alone, submit form
        handleSubmit(e, fetchImages);
        setFetchImages(false);
      }
    }
  };

  const [fetchImages, setFetchImages] = useState(false);

  const handlePaste = () => {
    setTimeout(adjustTextareaHeight, 0);
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputValue]);

  return (
    <>
      <div className="p-1">
        <FileUploadContainer />
      </div>

      <div className="flex flex-row items-center gap-4 text-textColor w-full px-4 pb-2 ">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e, fetchImages);
            setFetchImages(false);
          }}
          className="w-full relative border-2 border-accentTeal rounded-xl overflow-hidden pb-10  2xl:pb-12 bg-gray-100/10 backdrop-blur-lg shadow-md"
        >
          <Upload />

          <textarea
            ref={textareaRef}
            className="textarea-class bg-transparent w-full focus:outline-none resize-none pl-3 lg:p-2  "
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            // style={{ minHeight: '48px' }}
            placeholder={'Type or paste your prompt here...'}
          />
          <button
            type="submit"
            className="submit-button bg-primary p-2 rounded-xl absolute right-2 bottom-2"
            style={{ height: '34px', minWidth: '38px' }}
          >
            <ArrowUp size={20} className='text-white' />
          </button>
        </form>

        {gptModel !== 'vision' &&
          gptModel !== 'dalle-3' &&
          gptModel !== 'stablediffusion' &&
          gptModel !== 'gemini-image-generation' && (
            <div className="absolute bottom-8 left-40 ">
              <button
                className={`btn btn-sm justify-between border-1 border-accentTeal text-textColor ${
                  fetchImages
                    ? 'bg-accentTeal hover:bg-accentTeal text-white'
                    : 'bg-transparent hover:bg-transparent hover:border-charcoal'
                }`}
                onClick={() => setFetchImages(!fetchImages)}
              >
                Fetch Images
                <Camera size={20} />
              </button>

              <span className="absolute bottom-full left-[-100px] transform -translate-x-1/2 mb-2 px-3 py-1 bg-primary text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none whitespace-nowrap z-10">
                Fetch license free images related to this thread
              </span>
            </div>
          )}
        <FunctionalModelSelector />
      </div>
    </>
  );
};

export default PromptInput;
