import React, { useState } from 'react';
import Markdown from 'react-markdown';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';

import 'yet-another-react-lightbox/plugins/thumbnails.css';

const GPTResponseContainer = ({ content, externalImages }) => {
  const [tooltipText, setTooltipText] = useState('Copy');
  const [lightBoxOpen, setLightBoxOpen] = React.useState(false);

  const [creditToolTip, setcreditToolTip] = useState('Copy Credit');

  const { loginInfo } = useSelector((state) => state.auth);

  const handleCopyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    setTooltipText('Copied!');
    setTimeout(() => {
      setTooltipText('Copy');
    }, 2000);
  };

  const copyCreditToClipboard = (image) => {
    const htmlContent = `<p>Say Thanks: Photo by <a href="${image.attribution.profile}" target="_blank" rel="noopener noreferrer">${image.attribution.photographer}</a> on <a href="${image.externalUrl}" target="_blank" rel="noopener noreferrer">Unsplash</a></p>`;

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(htmlContent.trim());
        setcreditToolTip('Copied!');

        setTimeout(() => setcreditToolTip('Copy Credit'), 2000);
      } catch (error) {
        console.error('Failed to copy credit:', error);
        setcreditToolTip('Copy Failed');
      }
    };

    copyToClipboard();
  };

  const handleDownloadImage = async (imageURL, photographer, imageId) => {
    try {
      // Input validation
      if (!imageURL) throw new Error('Image URL is required');

      // Fetch image
      const response = await fetch(imageURL);
      if (!response.ok) throw new Error('Image fetch failed');

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);

      // Create filename with timestamp
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const filename = `chadgpt-${timestamp}.jpg`;

      // Create and trigger download
      const link = document.createElement('a');
      link.href = objectURL;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      // Immediate cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);

      // Fire-and-forget download tracker (if imageId exists)
      if (imageId && process.env.REACT_APP_API_URL && loginInfo?.token) {
        fetch(`${process.env.REACT_APP_API_URL}/chatbot/unsplash-download-tracker/${imageId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${loginInfo.token}`,
          },
        });
      }
    } catch (error) {
      console.error('Image download failed:', error);
    }
  };

  const customComponents = {
    strong: ({ node, ...props }) => <strong className="text-textColor font-semibold" {...props} />,
    code: ({ node, inline, ...props }) =>
      inline ? (
        <code className="text-textColor bg-gray-100 dark:bg-gray-800 px-1 rounded" {...props} />
      ) : (
        <pre className="text-textColor bg-gray-100 dark:bg-gray-800 p-2 rounded overflow-x-auto">
          <code {...props} />
        </pre>
      ),
    h1: ({ node, ...props }) => <h1 className="text-textColor text-2xl font-bold" {...props} />,
    h2: ({ node, ...props }) => <h2 className="text-textColor text-xl font-bold" {...props} />,
    h3: ({ node, ...props }) => <h3 className="text-textColor text-lg font-bold" {...props} />,
    h4: ({ node, ...props }) => <h4 className="text-textColor text-base font-bold" {...props} />,
    h5: ({ node, ...props }) => <h5 className="text-textColor text-sm font-bold" {...props} />,
    h6: ({ node, ...props }) => <h6 className="text-textColor text-xs font-bold" {...props} />,
    table: ({ node, ...props }) => (
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300 dark:border-gray-700" {...props} />
      </div>
    ),
    thead: ({ node, ...props }) => <thead className="bg-gray-50 dark:bg-gray-800" {...props} />,
    tbody: ({ node, ...props }) => <tbody {...props} />,
    tr: ({ node, ...props }) => <tr className="border-t border-gray-300 dark:border-gray-700" {...props} />,
    th: ({ node, ...props }) => (
      <th className="text-textColor border border-gray-300 dark:border-gray-700 px-4 py-2 font-semibold" {...props} />
    ),
    td: ({ node, ...props }) => (
      <td className="text-textColor border border-gray-300 dark:border-gray-700 px-4 py-2" {...props} />
    ),
  };

  return (
    <div className="border-2 border-borderColor rounded-lg p-4 flex flex-col w-full gap-3">
      {/* Header */}
      <div className="flex items-center justify-between gap-3">
        <div className="avatar flex items-center gap-2">
          <div className="w-8 rounded-full">
            <img src="/assets/imgs/chat-icon.webp" />
          </div>
          <h1 className="text-textColor font-semibold">ChadGPT</h1>
        </div>
        <span
          id="copy-btn"
          data-tip={tooltipText}
          className="cursor-pointer text-textColor tooltip"
          onClick={() => handleCopyToClipboard(content)}
        >
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 2.4V12.4C15 13.44 14.3311 14.32 13.3784 14.66C13.2365 14.72 13.0743 14.74 12.9324 14.78C12.9527 14.66 12.973 14.54 12.973 14.42V13.54C13.4595 13.36 13.7838 12.92 13.7838 12.42V2.4C13.7838 1.74 13.2365 1.2 12.5676 1.2H5.67568C5.16892 1.2 4.7027 1.52 4.52027 2H3.64865C3.52703 2 3.40541 2.02 3.28378 2.04C3.30405 1.9 3.34459 1.76 3.38514 1.62C3.70946 0.68 4.62162 0 5.67568 0H12.5676C13.9054 0 15 1.08 15 2.4ZM11.7568 5.6V15.6C11.7568 15.96 11.6757 16.28 11.5338 16.58C11.1486 17.42 10.2973 18 9.32432 18H2.43243C1.09459 18 0 16.92 0 15.6V5.6C0 4.62 0.587838 3.8 1.43919 3.42C1.74324 3.28 2.08784 3.2 2.43243 3.2H9.32432C10.6622 3.2 11.7568 4.28 11.7568 5.6ZM10.5405 5.6C10.5405 4.94 9.99324 4.4 9.32432 4.4H2.43243C1.76351 4.4 1.21622 4.94 1.21622 5.6V15.6C1.21622 16.26 1.76351 16.8 2.43243 16.8H9.32432C9.99324 16.8 10.5405 16.26 10.5405 15.6V5.6Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>

      {/* Markdown Content */}
      <div className="p-1">
        <Markdown className="prose max-w-none text-textColor" components={customComponents}>
          {content}
        </Markdown>
      </div>

      {/* External Images */}
      {externalImages && externalImages.length > 0 && (
        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Lightbox
            open={lightBoxOpen}
            close={() => setLightBoxOpen(false)}
            plugins={[Thumbnails]}
            slides={externalImages.map((image, index) => ({
              src: image.url,
              title: `Photo by ${image.attribution.photographer} on Unsplash`,
            }))}
          />
          {externalImages.map((image, index) => (
            <div key={index} className="relative flex flex-col items-center">
              <img
                src={image.url}
                alt={`Unsplash by ${image.attribution.photographer}`}
                className="w-full h-full object-cover rounded-lg cursor-pointer"
                onClick={() => setLightBoxOpen(true)}
              />
              <p
                className="text-sm text-center text-textColor mt-2 font-light"
                style={{ fontFamily: 'Raleway, sans-serif', fontWeight: 300 }}
              >
                Say Thanks: Photo by{' '}
                <a
                  href={image.attribution.profile + `?utm_source=chadgpt&utm_medium=referral`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary  "
                >
                  {image.attribution.photographer}
                </a>{' '}
                on{' '}
                <a
                  href={image.externalUrl + `?utm_source=chadgpt&utm_medium=referral`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary"
                >
                  Unsplash
                </a>
                <span
                  data-tip={creditToolTip}
                  className="ps-3 m t-3 cursor-pointer text-textColor tooltip"
                  onClick={() => copyCreditToClipboard(image)}
                >
                  <svg
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 2.4V12.4C15 13.44 14.3311 14.32 13.3784 14.66C13.2365 14.72 13.0743 14.74 12.9324 14.78C12.9527 14.66 12.973 14.54 12.973 14.42V13.54C13.4595 13.36 13.7838 12.92 13.7838 12.42V2.4C13.7838 1.74 13.2365 1.2 12.5676 1.2H5.67568C5.16892 1.2 4.7027 1.52 4.52027 2H3.64865C3.52703 2 3.40541 2.02 3.28378 2.04C3.30405 1.9 3.34459 1.76 3.38514 1.62C3.70946 0.68 4.62162 0 5.67568 0H12.5676C13.9054 0 15 1.08 15 2.4ZM11.7568 5.6V15.6C11.7568 15.96 11.6757 16.28 11.5338 16.58C11.1486 17.42 10.2973 18 9.32432 18H2.43243C1.09459 18 0 16.92 0 15.6V5.6C0 4.62 0.587838 3.8 1.43919 3.42C1.74324 3.28 2.08784 3.2 2.43243 3.2H9.32432C10.6622 3.2 11.7568 4.28 11.7568 5.6ZM10.5405 5.6C10.5405 4.94 9.99324 4.4 9.32432 4.4H2.43243C1.76351 4.4 1.21622 4.94 1.21622 5.6V15.6C1.21622 16.26 1.76351 16.8 2.43243 16.8H9.32432C9.99324 16.8 10.5405 16.26 10.5405 15.6V5.6Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </p>
              {/* Download Button */}
              <button
                onClick={() =>
                  handleDownloadImage(image.url, image.attribution.photographer, image.imageId)
                }
                className="absolute top-2 right-1 bg-white p-2 rounded-full shadow-md hover:bg-gray-200 transition"
              >
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 16l4-5h-3V4h-2v7H8l4 5zM5 18v2h14v-2H5z" />
                </svg>
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GPTResponseContainer;
