import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { getMe, setPlanSelected } from '../features/auth/authSlice';

const UpdatePaymentStatus = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const updateStatusWithRetry = async () => {
      const maxAttempts = 5;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/paypal/update-subscription-status`,
            {},
            {
              headers: {
                Authorization: `Bearer ${loginInfo?.token}`,
              },
            }
          );

          if (response.status === 200) {
            localStorage.setItem('planSelected', true);
            dispatch(getMe(loginInfo.token));
            dispatch(setPlanSelected(true));

            // Add 1 sec dalay before redirecting
            setTimeout(() => {
            }
            , 1000);
         
            navigate('/dashboard');
            return;
          }
        } catch (err) {
          attempts++;
          if (attempts === maxAttempts) {
            setError(`Failed to update payment status after ${maxAttempts} attempts.`);
            setLoading(false);
            return;
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }
    };

    updateStatusWithRetry();
  }, [navigate, loginInfo, dispatch]);

  const handleTryAgain = () => {
    navigate('/subscription-plans');
  };

  return (
    <section 
      className="bg-dark flex flex-col justify-center items-center h-screen px-4" 
      style={{
        backgroundImage: 'url(/assets/imgs/authBack.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex flex-col justify-center items-center text-center">
        <h1 className="text-3xl md:text-5xl font-semibold text-white mb-8 pb-5">
          Updating Payment Status...
        </h1>
        {loading ? (
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-primary"></div>
        ) : error ? (
          <div className="flex flex-col items-center gap-4">
            <p className="text-red-500 text-lg">{error}</p>
            <button
              onClick={handleTryAgain}
              className="bg-primary text-white px-6 py-2 rounded-md hover:bg-primary-dark transition-colors"
            >
              Try Again
            </button>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default UpdatePaymentStatus;