import axios from 'axios';

// const API_URL = "https://app.chadgpt.com/api/v1/auth/";

const API_URL = process.env.REACT_APP_API_URL + '/auth/';

// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL + 'register', userData);

  if (response.data) {
    localStorage.setItem('loginInfo', JSON.stringify(response.data));
    localStorage.setItem('expiresIn', response.data.expiresIn);
    localStorage.setItem('version', '2.1');
  }

  return response.data;
};

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData);

  if (response.data) {
    localStorage.setItem('loginInfo', JSON.stringify(response.data));
    localStorage.setItem('expiresIn', response.data.expiresIn);
    localStorage.setItem('version', '2.1');
  }

  return response.data;
};

// Get User Info
const getMe = async (token) => {
  const response = await axios.get(API_URL + 'getMe', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
    localStorage.setItem('planSelected', JSON.stringify(response.data.data.planSelected));
  }

  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('expiresIn');

  localStorage.removeItem('loginInfo');
  localStorage.removeItem('newChat');
  localStorage.removeItem('newFolder');
  localStorage.removeItem('userHistory');
  localStorage.removeItem('systemPrompts');
  localStorage.removeItem('ultimatePrompt');
  localStorage.removeItem('userPrompts');
  localStorage.removeItem('requiredPrompt');
};

const authService = {
  register,
  logout,
  login,
  getMe,
};

export default authService;
