import React from 'react';
import FileUploadCard from './FileUploadCard';
import { useDispatch, useSelector } from 'react-redux';

import { removeFile } from '../features/chats/chatSlice';

// Loader component with a circular spinner
const Loader = () => (
  <div className="flex justify-center items-center h-full">
    <div className="w-8 h-8 border-4 border-primary border-t-transparent border-solid rounded-full animate-spin"></div>
  </div>
);

const FileUploadContainer = () => {
  const dispatch = useDispatch();
  const { fileLoading, files } = useSelector((state) => state.chat);

  if (!fileLoading && (!files || files.length === 0)) {
    return null; // Don't render anything if not loading and no files.
  }

  const removeFileFromS3 = async (fileKey) => {
    try {
      const token = localStorage.getItem('token') || '';
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/chatbot/delete-file/?fileKey=${fileKey}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error(`Failed to delete file: ${response.statusText}`);
      }

      const data = await response.json();
    } catch (error) {
      return null;
    }
  };

  const dispatchFileRemoval = (index, s3Key) => {
    removeFileFromS3(s3Key);

    dispatch(
      removeFile({
        index,
        s3Key,
      })
    );
  };

  return (
    <div className="w-[100%] mx-auto p-4">
      {fileLoading ? (
        <Loader />
      ) : (
        <div className="w-[100%] mx-auto p-4">
          <div className="flex flex-col md:flex-row flex-wrap md:flex-nowrap overflow-auto md:overflow-x-auto md:space-x-4 space-y-4 md:space-y-0">
            {files.map((file, index) => (
              <div key={index} className="w-full md:w-1/3 flex-shrink-0">
                <FileUploadCard
                  fileName={file.originalName}
                  fileType={file.type}
                  onRemove={() => dispatchFileRemoval(index, file.s3Key)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadContainer;
