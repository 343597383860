import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getMe } from '../features/auth/authSlice';
import { useAppContext } from '../AppContext';

import NewChat from './NewChat';
import { useParams } from 'react-router-dom';
import QuestionContainer from './QuestionContainer';
import GPTResponseContainer from './GPTResponseContainer';
import PromptInput from './PromptInput';
import { toast } from 'react-toastify';
import GPTResponseImage from './GPTResponseImage';
import axios from 'axios';
import { getSharedChatHistory } from '../features/chats/chatSlice';
import ShareQuestionContainer from './ShareQuestionContainer';
import { PencilLine } from 'lucide-react';

const ShareConversation = () => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.auth);
  const { chatMessages } = useSelector((state) => state.chat);

  const [messages, setMessages] = useState(chatMessages || []);

  const bottomRef = useRef(null);

  useEffect(() => {
    if (chatMessages) {
      setMessages(chatMessages);
    }

    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleChatFetch = () => {
    const data = { id: id };

    dispatch(getSharedChatHistory(data));
  };

  useEffect(() => {
    handleChatFetch();
  }, []);

  return (
    <div className="flex flex-col text-white overflow-auto h-full ">
      <div className="my-4 flex justify-center">
        <a href="https://chadgpt.com/">
          <img src={'/assets/imgs/logo.svg'} alt="Logo" />
        </a>
      </div>
      <div
        className={`max-w-[1300px] mx-auto pb-[10rem] flex flex-col ${
          messages.length === 0 && 'h-full'
        }`}
      >
        {messages?.length !== 0 ? (
          <>
            <div className="px-5 pt-5 flex flex-col justify-start gap-6">
              {messages?.map((chat, index) => {
                return (
                  <div key={index} className="flex flex-col gap-4">
                    {/* Show text content first */}
                    {chat.role === 'user' ? (
                      <QuestionContainer content={chat.content} files={chat.files || []} />
                    ) : (
                      chat.content && <GPTResponseContainer content={chat.content} />
                    )}

                    {/* Show multiple images from imageUrl if available */}
                    {Array.isArray(chat.imageUrl) &&
                      chat.imageUrl.length > 0 &&
                      chat.imageUrl.map((url, imgIndex) => (
                        <GPTResponseImage key={`${index}-url-${imgIndex}`} imageURL={url} />
                      ))}

                    {/* Show a single image from imageKey if available */}
                    {chat.imageKey && (
                      <GPTResponseImage key={`${index}-key`} imageKey={chat.imageKey} />
                    )}
                  </div>
                );
              })}
            </div>
            <div ref={bottomRef} />
          </>
        ) : (
          <NewChat />
        )}
      </div>
    </div>
  );
};

export default ShareConversation;
