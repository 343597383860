import { Save } from 'lucide-react';
import React, { useState } from 'react';

const GPTResponseImage = ({ imageKey, imageURL }) => {
  const fallbackImage = '/path/to/fallback/image.png'; // Update with your actual fallback image path

  const [tooltipText, setTooltipText] = useState('Download');

  const handleDownload = async () => {
    let downloadUrl;
    const uniqueName = `chadgpt_image_${Date.now()}.jpg`; // Unique filename

    if (imageURL && imageURL.includes('unsplash.com')) {
      try {
        const response = await fetch(imageURL);
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectURL;
        link.download = uniqueName; // Assign unique name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(objectURL); // Cleanup
      } catch (error) {
        console.error('Error downloading Unsplash image:', error);
      }
      return;
    }

    if (imageKey) {
      downloadUrl = `${process.env.REACT_APP_API_URL}/chatbot/download-image?imageKey=${imageKey}`;
    } else if (imageURL) {
      downloadUrl = `${process.env.REACT_APP_API_URL}/chatbot/download-image?imageURL=${encodeURIComponent(imageURL)}`;
    } else {
      console.error('No valid image source provided.');
      return;
    }

    // Trigger the download with a unique name
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = uniqueName; // Assign unique name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTooltipText('Downloaded!');
    setTimeout(() => {
      setTooltipText('Download');
    }, 2000);
  };

  return (
    <div className="border-2 border-borderColor rounded-lg p-4 flex flex-col w-full gap-3">
      <div className="flex items-center justify-between gap-3">
        <div className="avatar flex items-center gap-2">
          <div className="w-8 rounded-full">
            <img src="/assets/imgs/chat-icon.webp" />
          </div>
          <h1 className="text-textColor font-semibold">ChadGPT</h1>
        </div>
        <span
          id="download-btn"
          data-tip={tooltipText}
          className="cursor-pointer text-textColor tooltip"
          onClick={handleDownload}
        >
          <Save size={20} />
        </span>
      </div>

      <div className="p-1">
        <img
          src={imageURL || `https://chadgpt.nyc3.digitaloceanspaces.com/${imageKey}`}
          alt=""
          // onError={(e) => (e.target.src = fallbackImage)}
        />
      </div>
    </div>
  );
};

export default GPTResponseImage;
