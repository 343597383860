import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { X } from 'lucide-react';
import { useAppContext } from '../../AppContext';

import { createNewPrompt, editUserPrompt } from '../../features/prompts/promptSlice';
import { createNewChat } from '../../features/chats/chatSlice';

export default function PromptModal({ type, open, setOpen, currentPromptId, promptContent }) {
  const dispatch = useDispatch();
  const { ultimatePrompt, requiredPrompt, isSuccess } = useSelector((state) => state.prompt);
  const { loginInfo } = useSelector((state) => state.auth);

  const { updateInputValue } = useAppContext();

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    content: promptContent || '',
  });

  const [editPrompt, setEditPrompt] = useState(false);

  const onChange = (e) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (type !== 'NewPrompt') {
      if (isSuccess || ultimatePrompt || requiredPrompt) {
        const prompt = ultimatePrompt ? ultimatePrompt : requiredPrompt;
        setFormData({
          name: prompt?.name,
          description: prompt?.description,
          content: prompt?.content,
        });
      }
    }
  }, [isSuccess, ultimatePrompt, requiredPrompt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type !== 'NewPrompt') {
      if (editPrompt) {
        const payload = {
          id: currentPromptId,
          name: formData.name,
          description: formData.description,
          content: formData.content,
          token: loginInfo.token,
        };

        dispatch(editUserPrompt(payload));
      } else {
        dispatch(createNewChat(loginInfo?.token));
        updateInputValue(formData.content);
      }
    } else {
      const payload = {
        name: formData.name,
        description: formData.description,
        content: formData.content,
        token: loginInfo.token,
      };
      dispatch(createNewPrompt(payload));
    }
    setOpen(false);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="absolute z-50 inset-0 overflow-y-auto" onClose={() => {}}>
          <div className="flex items-center justify-center min-h-screen p-4">
            {/* Overlay */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* Modal */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative bg-componentBg rounded-lg overflow-hidden mx-auto z-50">
                <div className="flex flex-row justify-end text-textColor me-4 pt-2 p-2">
                  <p className="hover:cursor-pointer" onClick={() => setOpen(false)}>
                    <X className="bg-dark text-white rounded-full p-1" size={24} />
                  </p>
                </div>
                <h1 className="text-center text-textColor text-3xl font-semibold">
                  {type === 'Saved' ? 'Saved Prompt' : 'Add Prompt'}
                </h1>
                <form onSubmit={handleSubmit} className="text-textColor flex flex-col">
                  <div className="px-[2rem] md:p-[4rem] py-[1.5rem] flex flex-col">
                    <div className="mb-2">
                      <label className="text-xl font-semibold">Name</label>
                      <input
                        className="border-b-2 border-borderColor w-full p-0 py-2 outline-none focus:border-b-2 focus:border-borderColor my-2 bg-componentBg"
                        name="name"
                        value={formData.name}
                        type="text"
                        disabled={type === 'NewPrompt' ? editPrompt : !editPrompt}
                        onChange={onChange}
                        placeholder="Enter Prompt name"
                        required
                      />
                    </div>

                    <div>
                      <label className="text-xl font-semibold">Description</label>

                      <textarea
                        className="w-full px-3 py-2 border my-2 bg-componentBg focus:border-2 focus:border-borderColor focus:outline-none"
                        rows="5"
                        cols="70"
                        name="description"
                        value={formData.description}
                        disabled={type === 'NewPrompt' ? editPrompt : !editPrompt}
                        onChange={onChange}
                        placeholder="Add Description"
                        required
                      />
                    </div>

                    <div>
                      <label className="text-xl font-semibold">Prompt Content</label>

                      <textarea
                        name="content"
                        className="w-full px-3 py-2 border my-2 bg-componentBg focus:border-2 focus:border-borderColor focus:outline-none"
                        rows="5"
                        cols="70"
                        value={formData.content}
                        disabled={type === 'NewPrompt' ? editPrompt : !editPrompt}
                        onChange={onChange}
                        placeholder="Add content"
                        required
                      />
                    </div>
                    <div className="flex justify-center items-center gap-3">
                      <button
                        type="submit"
                        className="bg-brightTeal border-brightTeal border-0 text-white font-bold font-regular px-5 py-2 rounded-lg"
                      >
                        {editPrompt || type == 'NewPrompt' ? 'Save' : 'Use Prompt'}
                      </button>
                      {type === 'UserPrompt' && (
                        <button
                          type="button"
                          onClick={() => setEditPrompt(true)}
                          className={`bg-brightTeal border-brightTeal border-0 text-dark font-bold font-regular px-5 py-2 rounded-lg ${
                            editPrompt && 'hidden'
                          }`}
                        >
                          Edit Prompt
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
