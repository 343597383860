import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import promptService from './promptService';

// Get info from localStorage
const systemPrompts = JSON.parse(localStorage.getItem('systemPrompts'));
const ultimatePrompt = JSON.parse(localStorage.getItem('ultimatePrompt'));
const userPrompts = JSON.parse(localStorage.getItem('userPrompts'));
const requiredPrompt = JSON.parse(localStorage.getItem('requiredPrompt'));

const initialState = {
  userPrompts: userPrompts ? userPrompts : null,
  requiredPrompt: requiredPrompt ? requiredPrompt : null,
  systemPrompts: systemPrompts ? systemPrompts : null,
  ultimatePrompt: ultimatePrompt ? ultimatePrompt : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Get System prompts
export const getSystemPrompts = createAsyncThunk(
  'prompt/getSystemPrompts',
  async (token, thunkAPI) => {
    try {
      return await promptService.getSystemPrompts(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get system prompt by id
export const getSystemPromptByID = createAsyncThunk(
  'prompt/getUltimatePrompt',
  async (data, thunkAPI) => {
    try {
      return await promptService.getSystemPromptByID(data.id, data.token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new prompt
export const createNewPrompt = createAsyncThunk('prompt/newPrompt', async (data, thunkAPI) => {
  try {
    return await promptService.createNewPrompt(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Create new Prompt folder
export const createNewPromptFolder = createAsyncThunk(
  'prompt/newPromptFolder',
  async (token, thunkAPI) => {
    try {
      return await promptService.createNewPromptFolder(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get user prompts tree
export const getUserPrompts = createAsyncThunk('prompt/getUserPrompts', async (token, thunkAPI) => {
  try {
    return await promptService.getUserPrompts(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get user prompt by id
export const getUserPromptByID = createAsyncThunk(
  'prompt/getUserPrompt',
  async (data, thunkAPI) => {
    try {
      return await promptService.getUserPromptByID(data.id, data.token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Edit user prompt
export const editUserPrompt = createAsyncThunk('prompt/editUserPrompt', async (data, thunkAPI) => {
  try {
    return await promptService.editUserPrompt(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Update prompt parent (dragndrop)
export const updatePromptParent = createAsyncThunk(
  'prompt/updatePromptParent',
  async (data, thunkAPI) => {
    try {
      return await promptService.updatePromptParent(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    removeStructureFromUserPrompts: (state, action) => {
      const deletedStructureId = action.payload;
      state.userPrompts = state.userPrompts.filter(
        (structure) => structure.id !== deletedStructureId
      );
      localStorage.setItem('userPrompts', JSON.stringify(state.userPrompts));
    },
    updatePromptStructureName: (state, action) => {
      const data = action.payload;
      state.userPrompts = state.userPrompts.map((prompt) =>
        prompt._id === data.id ? { ...prompt, text: data.name } : prompt
      );

      localStorage.setItem('userPrompts', JSON.stringify(state.userPrompts));
    },
    dragPromptToFolder: (state, action) => {
      const data = action.payload;
      state.userPrompts = state.userPrompts.map((structure) =>
        structure._id === data.promptId ? { ...structure, parent: data.parent } : structure
      );

      localStorage.setItem('userPrompts', JSON.stringify(state.userPrompts));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemPrompts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSystemPrompts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.systemPrompts = action.payload;
      })
      .addCase(getSystemPrompts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.systemPrompts = null;
      })
      .addCase(getSystemPromptByID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSystemPromptByID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ultimatePrompt = action.payload;
        state.requiredPrompt = null;
      })
      .addCase(getSystemPromptByID.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.ultimatePrompt = null;
      })
      .addCase(createNewPrompt.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewPrompt.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userPrompts = action.payload;
      })
      .addCase(createNewPrompt.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createNewPromptFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewPromptFolder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userPrompts = action.payload;
      })
      .addCase(createNewPromptFolder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserPrompts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserPrompts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userPrompts = action.payload;
      })
      .addCase(getUserPrompts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserPromptByID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserPromptByID.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requiredPrompt = action.payload;
        state.ultimatePrompt = null;
      })
      .addCase(getUserPromptByID.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editUserPrompt.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editUserPrompt.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userPrompts = action.payload;
      })
      .addCase(editUserPrompt.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePromptParent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePromptParent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userPrompts = action.payload;
      })
      .addCase(updatePromptParent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  reset,
  removeStructureFromUserPrompts,
  updatePromptStructureName,
  dragPromptToFolder,
} = promptSlice.actions;
export default promptSlice.reducer;
