import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { login, resetError } from '../features/auth/authSlice';

import { getMe } from '../features/auth/authSlice';
import { getUserHistory } from '../features/chats/chatSlice';
import Turnstile, { useTurnstile } from 'react-turnstile';

import { getSystemPrompts, getUserPrompts } from '../features/prompts/promptSlice';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const turnstile = useTurnstile();

  const turnstileSiteKey = process.env.REACT_APP_TURNSTILE_SITE_KEY;

  const { email, password } = formData;

  const [turnstileToken, setturnstileToken] = useState('');
  // const googleAuthUrl = "https://app.chadgpt.com/api/v1/auth/google"

  const googleAuthUrl = `${process.env.REACT_APP_API_URL}/auth/google`;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { loginInfo, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

  const [prevError, setPrevError] = useState(false);
  useEffect(() => {
    //Display toast when isError changes from false to true
    if (isError && !prevError) {
      toast.error(message || 'Invalid credentials. Please try again!', {
        closeOnClick: true,
        autoClose: 2000,
      });
      dispatch(resetError());
    }
    setPrevError(isError);

    // Handle successful login
    if (isSuccess && loginInfo?.token) {
      dispatch(getMe(loginInfo.token));
      dispatch(getUserHistory(loginInfo.token));
      dispatch(getSystemPrompts(loginInfo.token));
      dispatch(getUserPrompts(loginInfo.token));
      navigate('/dashboard');
    }
  }, [isError, isSuccess, loginInfo, message, prevError, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!turnstileToken) {
      toast.error('Please complete the Captcha', {
        closeOnClick: true,
        autoClose: 2000,
      });
      return;
    }

    const userData = {
      email,
      password,
      'cf-turnstile-response': turnstileToken,
    };

    turnstile.reset();
    dispatch(login(userData));
  };

  return (
    <section
      className="bg-dark flex flex-col justify-center items-center h-screen"
      style={{
        backgroundImage: 'url(/assets/imgs/authBack.png)',
        backgroundSize: 'cover', // Cover the entire section
        backgroundPosition: 'center', // Center the background image
        backgroundRepeat: 'no-repeat', // Do not repeat the image
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl md:text-5xl font-semibold text-white text-center">
          Welcome to{' '}
          <span
            className="border-s-4 border-primary"
            style={{
              background: 'linear-gradient(90deg, #32666F 0%, rgba(32, 253, 201, 0.00) 100%)',
            }}
          >
            ChadGPT
          </span>
        </h1>

        <div className="mt-14">
          <form
            className="flex flex-col justify-center items-center gap-5 w-full"
            id="loginForm"
            onSubmit={onSubmit}
          >
            <div className="input-group">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={onChange}
                className="px-3 py-4 w-96 focus:outline-primary"
                autoComplete="email"
                required
              />
              <label htmlFor="email">Email Address</label>{' '}
              {/* Associate label with input via htmlFor */}
            </div>
            <div className="input-group">
              <input
                type="password"
                id="password" // Add an id for the input
                name="password"
                value={password}
                onChange={onChange}
                className="px-3 py-4 w-96 focus:outline-primary"
                autoComplete="password" // Indicates this is a password field
                required
              />
              <label htmlFor="password">Password</label>{' '}
              {/* Associate label with input via htmlFor */}
            </div>

            <Turnstile
              sitekey={turnstileSiteKey}
              onVerify={(token) => {
                setturnstileToken(token);
              }}
            />

            <button
              type="submit"
              className="w-96 p-4 bg-primary text-white rounded-md font-semibold text-lg"
            >
              {isLoading ? 'Loading...' : 'Continue'}
            </button>
          </form>
        </div>

        <div className="flex justify-center items-center mt-3">
          <p className="text-white">
            Don't have an account?{'  '}
            <Link to="/register" className="text-grey font-semibold">
              Sign up
            </Link>
          </p>
        </div>
        <div className="flex justify-center items-center mt-3">
          <p className="text-white">
            Forgot Password?{'  '}
            <Link to="/forget-password" className="text-grey font-semibold">
              Reset
            </Link>
          </p>
        </div>

        <div className="mt-10 flex items-center justify-center gap-2">
          <hr className="border-b-2 border-grey w-40" />
          <p className="text-white">OR</p>
          <hr className="border-b-2 border-grey w-40" />
        </div>

        <div className="mt-6">
          <a
            href={googleAuthUrl}
            className="w-96 p-4 text-white border-2 border-grey rounded-full text-lg font-semibold flex items-center gap-4"
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8 10.2083C18.8 9.55831 18.7417 8.93331 18.6333 8.33331H10V11.8833H14.9333C14.7167 13.025 14.0667 13.9916 13.0917 14.6416V16.95H16.0667C17.8 15.35 18.8 13 18.8 10.2083Z"
                fill="#4285F4"
              />
              <path
                d="M9.99974 19.1667C12.4747 19.1667 14.5497 18.35 16.0664 16.95L13.0914 14.6417C12.2747 15.1917 11.2331 15.525 9.99974 15.525C7.61641 15.525 5.59141 13.9167 4.86641 11.75H1.81641V14.1167C3.32474 17.1083 6.41641 19.1667 9.99974 19.1667Z"
                fill="#34A853"
              />
              <path
                d="M4.86634 11.7417C4.68301 11.1917 4.57467 10.6083 4.57467 10C4.57467 9.39166 4.68301 8.80833 4.86634 8.25833V5.89166H1.81634C1.19134 7.125 0.833008 8.51666 0.833008 10C0.833008 11.4833 1.19134 12.875 1.81634 14.1083L4.19134 12.2583L4.86634 11.7417Z"
                fill="#FBBC05"
              />
              <path
                d="M9.99974 4.48331C11.3497 4.48331 12.5497 4.94998 13.5081 5.84998L16.1331 3.22498C14.5414 1.74165 12.4747 0.833313 9.99974 0.833313C6.41641 0.833313 3.32474 2.89165 1.81641 5.89165L4.86641 8.25831C5.59141 6.09165 7.61641 4.48331 9.99974 4.48331Z"
                fill="#EA4335"
              />
            </svg>
            Continue with Google
          </a>
        </div>
      </div>
    </section>
  );
};

export default Login;
