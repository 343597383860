import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerificationPageData = () => {
  const [text, setText] = useState(
    'A verification email has been sent to your email address. Please verify your email to activate your account and gain access.'
  );
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTimer, setCooldownTimer] = useState(120); // 120 seconds or 2 minutes

  const { loginInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loginInfo && loginInfo.user.email) {
      setText(
        <span className="flex flex-col gap-2 items-center">
          <p className="text-lg md:text-xl">A verification email has been sent to</p>
          <span
            className="font-bold text-2xl md:text-3xl"
            style={{ color: 'rgba(32, 253, 201, 0.50)' }}
          >
            {loginInfo.user.email}
          </span>
          <p className="text-lg md:text-xl">Please verify your email to activate your account and gain access.</p>
        </span>
      );
    }
  }, [loginInfo]);
  

  const EmailVerificationSend = async () => {
    setText('Sending Email...');
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/verify-email`, {
        headers: {
          Authorization: `Bearer ${loginInfo.token}`,
        },
      });
      setText(
        <span className="flex flex-col gap-2 items-center">
          <p className="text-lg md:text-xl">A verification email has been sent to</p>
          <span
            className="font-bold text-2xl md:text-3xl"
            style={{ color: 'rgba(32, 253, 201, 0.50)' }}
          >
            {loginInfo.user.email}
          </span>
          <p className="text-lg md:text-xl">Please verify your email to activate your account and gain access.</p>
        </span>
      );      setCooldown(true);
      let counter = cooldownTimer;
      const interval = setInterval(() => {
        setCooldownTimer(--counter);
        if (counter <= 0) {
          clearInterval(interval);
          setCooldown(false);
          setCooldownTimer(120); // Reset timer for next use
        }
      }, 1000);
    } catch (error) {
      setText('An error occurred while sending your email');
    }
  };

  useEffect(() => {
    if (!loginInfo) {
      navigate('/');
    }
  }, [loginInfo, navigate]);

  return (
    <section
      className="bg-dark flex flex-col justify-center items-center h-screen px-4"
      style={{
        backgroundImage: 'url(/assets/imgs/authBack.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex flex-col justify-center items-center text-center">
        <h1 className="text-3xl md:text-5xl font-semibold text-white mb-8 pb-5">
          Welcome to{' '}
          <span
            className="border-s-4 border-primary relative"
            style={{
              background:
                'linear-gradient(90deg, rgba(32, 253, 201, 0.50) 0%, rgba(32, 253, 201, 0.00) 100%)',
            }}
          >
            ChadGPT
          </span>
        </h1>

        <div className="text-lg md:text-2xl font-semibold text-white mb-6">
          <h4>{text}</h4>
        </div>
        <button
          className="w-full md:w-96 p-4 bg-primary text-white rounded-md font-semibold text-lg transition-transform transform hover:scale-105"
          onClick={EmailVerificationSend}
          disabled={cooldown}
        >
          {cooldown ? `Please wait ${cooldownTimer} seconds to resend` : 'Resend Email'}
        </button>
      </div>
    </section>
  );
};

export default VerificationPageData;
