import { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../AppContext';
import { useSelector } from 'react-redux';

const FunctionalModelSelector = () => {
  const { functionalNavigation, updateGPTModel, gptModel } = useAppContext();
  const theme = useSelector((state) => state.auth.theme);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const modelOptions = {
    chat: [
      {
        id: 'gemini-flash',
        name: 'Gemini 2.0',
        tooltip: 'Gemini 2.0 Flash offers enhanced performance and speed.',
      },
      {
        id: '3.5',
        name: 'GPT 3.5',
        tooltip: 'GPT-3.5 is versatile for human-like text generation.',
      },
      { id: '4', name: 'GPT 4', tooltip: 'GPT-4.0 offers enhanced performance.' },
      { id: 'claude', name: 'Claude', tooltip: 'Claude AI excels at conversational context.' },
      { id: 'Llama3', name: 'Llama 3', tooltip: 'Llama 3 is ideal for complex reasoning.' },
      { id: 'grok', name: 'Grok', tooltip: 'Grok AI integrates real-time data.' },
    ],
    reasoning: [
      {
        id: 'o3-mini',
        name: 'GPT-o3 Mini',
        tooltip: 'GPT-o3 Mini is ideal for lightweight tasks.',
      },
      { id: 'o1', name: 'o1', tooltip: 'GPT-o1 is great for lightweight, efficient tasks.' },
      {
        id: 'gemini-pro',
        name: 'Gemini 2.0 Pro',
        tooltip: 'Gemini 2.0 Pro offers superior performance.',
      },
    ],
    generateImages: [
      { id: 'dalle-3', name: 'DALL-E', tooltip: 'DALL-E generates detailed images.' },
      {
        id: 'stablediffusion',
        name: 'Stable Diffusion',
        tooltip: 'Stable Diffusion offers creative flexibility.',
      },
      { id: 'gemini-image-generation', name: 'Gemini', tooltip: 'Gemini is known for its speed.' },
    ],
    queryImages: [
      { id: 'vision', name: 'GPT Vision', tooltip: 'Vision is great for analyzing images.' },
    ],
    deepResearch: [
      {
        id: 'gemini-2.0-flash-thinking',
        name: 'Gemini 2.0',
        tooltip: 'Gemini 2.0 Flash Thinking pushes AI research.',
      },
    ],
  };

  const currentModels = modelOptions[functionalNavigation] || [];
  const selectedModel = currentModels.find((model) => model.id === gptModel) ||
    currentModels[0] || { name: 'Select Model' };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="absolute bottom-8 ml-2" ref={dropdownRef}>
      <div className="dropdown dropdown-top w-full">
        {/* Transparent Button showing selected model */}
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="btn btn-sm w-[130px] justify-between bg-transparent border-1 border-accentTeal text-textColor hover:bg-transparent focus:border:charcoal hover:border-charcoal"
        >
          {selectedModel.name}
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
          </svg>
        </button>

        {/* Dropdown menu */}
        {isDropdownOpen && currentModels.length > 0 && (
          <ul className="w-[200px] dropdown-content menu p-2 shadow bg-white/10 backdrop-blur-lg max-h-90 overflow-y-auto rounded-box z-10">
            {currentModels.map(({ id, name, tooltip }) => (
              <li key={id} className="relative">
                <div className="tooltip tooltip-right">
                  <button
                    onClick={() => {
                      updateGPTModel(id);
                      setIsDropdownOpen(false);
                    }}
                    className={`w-full text-left px-4 py-2 text-sm md:text-base font-medium text-textColor hover:bg-primary hover:text-white ${
                      gptModel === id ? 'bg-primary text-white' : ''
                    }`}
                  >
                    {name}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {currentModels.length === 0 && (
        <span className="text-sm text-gray-600 text-center">No models available</span>
      )}
    </div>
  );
};

export default FunctionalModelSelector;
