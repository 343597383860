import { File, Trash } from 'lucide-react';
import React from 'react';

const FileUploadCard = ({ fileName, fileType, onRemove, link }) => {
  const truncateFileName = (name) => {
    const extension = name.split('.').pop();
    const nameWithoutExtension = name.replace(`.${extension}`, '');
    return nameWithoutExtension.length > 12
      ? `${nameWithoutExtension.substring(0, 5)}...${nameWithoutExtension.slice(-5)}.${extension}`
      : name;
  };

  const openLink = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <div
      className="flex items-center justify-center p-4 bg-gray-100 dark:bg-dark relative group"
      onClick={openLink}
    >
      <div className="flex items-center space-x-2">
        <div className="p-3 bg-dark dark:bg-gray-100 rounded-full">
          <File className="h-6 w-6 text-white dark:text-dark" />
        </div>
        <div>
          <span className="block font-medium text-gray-800 dark:text-gray-100">
            {truncateFileName(fileName)}
          </span>
          <span className="block text-sm text-gray-500 dark:text-gray-400">{fileType}</span>
        </div>
      </div>
      {onRemove && (
        <Trash
          className="absolute top-0 right-0 mt-2 mr-2 p-1 bg-red-500 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer"
          onClick={onRemove}
        />
      )}
    </div>
  );
};

export default FileUploadCard;
