import { createContext, useContext, useState } from 'react';
import { updateFiles } from './features/chats/chatSlice';
import { useDispatch } from 'react-redux';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState('');
  const [gptModel, setGptModel] = useState('gemini-flash');
  const [functionalNavigation, setFunctionalNavigation] = useState('chat');
  const [currentChat, setCurrentChat] = useState('');

  const dispatch = useDispatch();
  const updateInputValue = (newValue) => {
    setInputValue(newValue);
  };

  const updateGPTModel = (newVal) => {
    setGptModel(newVal);
    dispatch(updateFiles([]));
  };

  const updateCurrentChat = (newVal) => {
    setCurrentChat(newVal);
  };

  const updateFunctionalNavigation = (newVal) => {
    setFunctionalNavigation(newVal);

    const modelMap = {
      chat: 'gemini-flash',
      reasoning: 'o3-mini',
      generateImages: 'dalle-3',
      queryImages: 'vision',
      deepResearch: 'gemini-2.0-flash-thinking',
    };

    if (modelMap[newVal]) {
      updateGPTModel(modelMap[newVal]);
    }
  };

  return (
    <AppContext.Provider
      value={{
        inputValue,
        updateInputValue,
        gptModel,
        updateGPTModel,
        currentChat,
        updateCurrentChat,
        functionalNavigation,
        updateFunctionalNavigation,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
