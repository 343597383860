import React from 'react';
import { useAppContext } from '../AppContext';
import { useSelector, useDispatch } from 'react-redux';

const FunctionalNavigationButton = ({ title, name }) => {
  const { functionalNavigation, updateFunctionalNavigation } = useAppContext();
  const theme = useSelector((state) => state.auth.theme);

  return (
    <button
      className={`w-full px-3 py-2 rounded-lg font-[500] text-textColor border-2 border-primary hover:text-white ${
        functionalNavigation === name
          ? `text-white ${theme === 'dark' ? 'bg-charcoal hover:bg-charcoal' : 'bg-brightTeal hover:bg-brightTeal'}`
          : `hover:${theme === 'dark' ? 'bg-charcoal' : 'bg-brightTeal'}`
      }`}
      onClick={() => updateFunctionalNavigation(name)}
    >
      {title}
    </button>
  );
};

export default FunctionalNavigationButton;
