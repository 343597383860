import React from 'react';
import { Check } from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {useState} from 'react';

const SubscriptionCard = ({ name, price, type, points, stripeId, paypalId }) => {
  const { loginInfo } = useSelector((state) => state.auth);
  const [takeTrial, setTakeTrial] = useState(null);

  const subscribe = async (paymentMethod) => {
    try {
      if(paymentMethod == 'card'){
        const result = await axios.get(`/api/v1/stripe/checkout/${stripeId}?takeTrial=${takeTrial}`, {
          headers: {
            Authorization: `Bearer ${loginInfo.token}`,
          },
        });
        const checkoutUrl = result.data.session.url;
  
        // Redirect to checkout URL
        localStorage.setItem('planSelected', true);
        window.location.href = checkoutUrl;

      } else if(paymentMethod == 'paypal'){
        const result = await axios.get(`/api/v1/paypal/checkout/${paypalId}?takeTrial=${takeTrial}?&planInterval=${type}`, {
          headers: {
            Authorization: `Bearer ${loginInfo.token}`,
          },
        });
        const checkoutUrl = result.data.session.links[0].href;
  
        // Redirect to checkout URL
       window.location.href = checkoutUrl;

      }
      else {
        toast.error('Please select a payment method.');
        return;
      }
 
    } catch (error) {
      toast.error('Failed to initiate subscription. Please try again.');

      console.error('Subscription error:', error);
    }
  };

  const billingText = type === 'year' ? '$98.97 Billed Annually' : 'Billed Monthly';

  return (
    <div className="bg-white flex flex-col w-[22.5rem] md:w-[31rem] rounded-3xl py-7 md:py-5 px-5 md:px-7">
      <div className="flex flex-col items-center text-center gap-3 pb-8 border-b-2 border-gray-400/10">
      {takeTrial === null ? (
        <>
        <div>
          <h3 className="text-dark font-bold text-3xl md:text-4xl">{name}</h3>
          <p className={`text-primary mt-1 ${type === '' && 'opacity-0 cursor-default'}`}>
            Free for 7-days, then just
          </p>
        </div>

        <div className="flex flex-col items-center gap-7 w-full max-w-[350px]">
          <span className="text-xl flex items-end font-medium text-dark">
            $<span className="text-4xl md:text-5xl font-semibold text-dark">{price}</span>
            /month
          </span>
          <p className="text-sm text-dark -mt-5 pl-20">{billingText}</p>
          <button
            onClick={() => setTakeTrial(true)}
            className="w-full bg-brightTeal text-lg text-white rounded-full font-semibold py-3 transition duration-500 ease-in-out hover:bg-charcoal hover:text-white"
          >
            Verify and Start 7-Day Trial
          </button>
          <button
            onClick={() => setTakeTrial(false)}
            className="w-full bg-accentTeal text-lg text-white rounded-full font-semibold py-3 transition duration-500 ease-in-out hover:bg-brightTeal hover:text-white"
          >
            Skip Trial & Start My Subscription
          </button>
        </div>
        </>): (
           <>
           <div>
             <h3 className="text-dark font-bold text-3xl md:text-4xl">{name}</h3>
             <p className={`text-primary mt-1 ${type === '' && 'opacity-0 cursor-default'}`}>
               Free for 7-days, then just
             </p>
           </div>
   
           <div className="flex flex-col items-center gap-7 w-full max-w-[350px]">
             <span className="text-xl flex items-end font-medium text-dark">
               $<span className="text-4xl md:text-5xl font-semibold text-dark">{price}</span>
               /month
             </span>
             <p className="text-sm text-dark -mt-5 pl-20">{billingText}</p>
             <button
               onClick={() => {
                 subscribe('card');
               }}
               className="w-full bg-brightTeal text-lg text-white rounded-full font-semibold py-3 transition duration-500 ease-in-out hover:bg-charcoal hover:text-white"
             >
               Card
             </button>
             <button
               onClick={() => {
                  subscribe('paypal');
               }}
               className="w-full bg-accentTeal text-lg text-white rounded-full font-semibold py-3 transition duration-500 ease-in-out hover:bg-brightTeal hover:text-white"
             >
               Paypal
             </button>
           </div>
           </>

        )  }
        
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-primary text-xl text-center mt-5">Features</h3>
        <div className="flex flex-col text-dark gap-3">
          {points.map((point, index) => (
            <div key={index} className="flex items-center gap-2">
              <Check />
              <p className="text-lg">{point}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
