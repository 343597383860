import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getMe } from '../features/auth/authSlice';
import { useAppContext } from '../AppContext';

import NewChat from './NewChat';
import StopGenerating from './StopGenerating';
import QuestionContainer from './QuestionContainer';
import GPTResponseContainer from './GPTResponseContainer';
import PromptInput from './PromptInput';
import { toast } from 'react-toastify';
import GPTResponseImage from './GPTResponseImage';
import axios from 'axios';
import { PencilLine } from 'lucide-react';
import { getUserHistory } from '../features/chats/chatSlice';

import ShareConversationModal from './modals/ShareConversationModal';
import { updateFiles } from '../features/chats/chatSlice';

const Conversation = () => {
  const dispatch = useDispatch();

  const [isChating, setIsChating] = useState(false);

  const { temprature } = useSelector((state) => state.auth);

  const { inputValue, updateInputValue, gptModel, currentChat } = useAppContext();

  const [modalType, setModalType] = useState('');
  const [GPTLoading, setGPTLoading] = useState(false);

  const { loginInfo } = useSelector((state) => state.auth);
  const { chatMessages } = useSelector((state) => state.chat);

  const [messages, setMessages] = useState(chatMessages || []);

  const files = useSelector((state) => state.chat.files);

  if (messages.length === 0 && inputValue !== '') {
    // 10000 milliseconds = 10 seconds
  }

  const handleSubmit = async (e, fetchImages) => {
    e.preventDefault();
    setIsChating(true);
    updateInputValue('');

    if (inputValue === '') return;

    dispatch(updateFiles([]));

    // if (!pro) {
    //   if (credit <= 0) {
    //     toast.error('Upgrade to pro to continue using without limits.', {
    //       hideProgressBar: true,
    //       draggable: true,
    //     })
    //     setPricingModalOpen(true)
    //     return
    //   } else {
    //     setcredit((credit) => credit - 1)
    //   }
    // }
    const fileData = files?.length > 0 ? files.map((file) => file.s3Key) : [];

    setMessages((messages) => [
      ...messages,
      { role: 'user', content: inputValue, files: fileData },
    ]);
    setGPTLoading(true);
    // let inputmessage = message
    // setMessage('')
    setMessages((messages) => [...messages, { role: 'assistant', content: '' }]);

    try {
      if (!['dalle-3', 'stablediffusion', 'gemini-image-generation'].includes(gptModel)) {
        let apiUrl;

        // Check for different model types and assign the appropriate API URL
        if (gptModel === 'Llama3') {
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/LlamaResponse`;
        } else if (gptModel === 'claude') {
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/getclauderesponse`;
        } else if (gptModel === 'grok') {
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/grokResponse`;
        } else if (gptModel === 'gemini-flash' || gptModel === 'gemini-pro') {
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/geminiResponse`;
        } else if (gptModel === 'o1') {
          // Specific API call for the "01-mini" model
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/geto1miniresponse`;

          setMessages((messages) => [
            ...messages.slice(0, -1),
            {
              role: 'assistant',
              content: 'Generating Response...',
            },
          ]);
        } else if (gptModel === 'gemini-2.0-flash-thinking') {
          // Specific API call for the "01-mini" model
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/google-deep-response`;

          setMessages((messages) => [
            ...messages.slice(0, -1),
            {
              role: 'assistant',
              content: 'Generating Response...',
            },
          ]);
        } else {
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/getresponse`;
        }

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${loginInfo?.token}`,
          },
          body: JSON.stringify({
            message: inputValue,
            id: currentChat,
            model: gptModel,
            temprature: temprature,
            fetchImages: fetchImages,
            ...(gptModel === 'vision' && { files: files }),
          }),
        });

        // If the model is "01-mini", handle non-stream response
        if (gptModel === 'o1' || gptModel === 'gemini-2.0-flash-thinking') {
          const result = await response.json();

          if (response.status === 200) {
            // Update messages with the final result (non-stream response)
            setMessages((messages) => [
              ...messages.slice(0, -1),
              {
                role: 'assistant',
                content: result.message,
                externalImages: result.externalImages?.length > 0 ? result.externalImages : [],
              },
            ]);
          } else {
            // Handle errors and update messages accordingly
            setMessages((messages) => [
              ...messages.slice(0, -1),
              { role: 'assistant', content: 'Failed to fetch response.' },
            ]);
          }
        } else {
          const reader = response.body.getReader();
          let accumulatedText = '';
          let imageList = [];

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunkText = new TextDecoder('utf-8').decode(value);

            // Check if this chunk contains the image delimiter
            if (chunkText.includes('__IMAGES__')) {
              const [textPart, imagePart] = chunkText.split('__IMAGES__');
              accumulatedText += textPart;

              try {
                const imageData = JSON.parse(imagePart);
                imageList = imageData.externalImages;
              } catch (e) {
                console.error('Error parsing image data:', e);
              }
            } else {
              accumulatedText += chunkText;
            }

            if (response.status === 200) {
              setMessages((messages) => [
                ...messages.slice(0, -1),
                {
                  role: 'assistant',
                  content: accumulatedText,
                  externalImages: imageList, // Add images to the message
                },
              ]);
            } else {
              let eresp = JSON.parse(accumulatedText);
              eresp = eresp.message;
              setMessages((messages) => [
                ...messages,
                {
                  role: 'assistant',
                  content: eresp,
                  images: [],
                },
              ]);
              break;
            }
          }
        }
      } else {
        // Handle DALL-E image generation
        setMessages((messages) => [
          ...messages.slice(0, -1),
          {
            role: 'assistant',
            content: 'Generating Image...',
          },
        ]);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/chatbot/generateImage`,
            {
              model: gptModel,
              message: inputValue,
              id: currentChat,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${loginInfo?.token}`,
              },
            }
          );

          setMessages((messages) => [
            ...messages.slice(0, -1),
            {
              role: 'assistant',
              ...(response.data?.imageKey
                ? { imageKey: response.data.imageKey }
                : { imageUrl: [response.data.imageUrl] }),
            },
          ]);
        } catch (error) {
          console.error('Error generating image:', error);
          setMessages((messages) => [
            ...messages.slice(0, -1),
            {
              role: 'assistant',
              content: 'Failed to generate image.',
            },
          ]);
        }
      }

      setGPTLoading(false);

      if (messages.length === 0) {
        dispatch(getUserHistory(loginInfo.token));
      }

      dispatch(getMe(loginInfo?.token));
      // updateCredit()
    } catch (err) {
      alert(err);

      // Display toast error instead of writing to messages
      // toast.error('An error occurred. Please try again later.', {
      //   hideProgressBar: true,
      //   draggable: true,
      // })

      // Optionally, you can also perform additional error handling or logging here
    }
  };

  const bottomRef = useRef(null);

  useEffect(() => {
    if (chatMessages) {
      setMessages(chatMessages);
    }

    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [chatMessages]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [messages]);

  const [shareButton, setShareButton] = useState(true);

  const handleShareConversation = async () => {
    setShareButton(false);
    const data = await axios.post(
      process.env.REACT_APP_API_URL + `/chatbot/changesharedhistorybyid`,
      { id: messages[0].structure, isShared: true },
      {
        headers: {
          Authorization: `Bearer ${loginInfo.token}`,
        },
      }
    );
    setShareButton(true);
    // Check if response code is 200 of data
    if (data.status === 200) {
      // Copy url to clickboard
      navigator.clipboard.writeText(
        `${window.location.origin}/shareconversation/${messages[0].structure}`
      );
      toast.success('Link copied to clipboard!', {
        hideProgressBar: true,
        draggable: true,
        autoClose: 2000, // Will close after 2 seconds
        position: 'top-right', // Position at bottom center of screen
        icon: '📋', // Adds a clipboard icon
      });
    }
  };

  return (
    <div className="flex flex-col overflow-auto h-full ">
      <div className="flex justify-end">
        {' '}
        {/* This div wraps your button and pushes it to the right */}
        {messages?.length !== 0 && (
          <button
            onClick={handleShareConversation}
            disabled={!shareButton}
            className={`text-textColor flex items-center justify-between px-3 py-3 mx-2 my-3 rounded-lg font-[500] border-2 border-borderColor ${
              !shareButton ? 'opacity-50 cursor-not-allowed' : 'hover:bg-charcoal'
            }`}
            style={{ width: 'auto' }}
          >
            {shareButton ? 'Share Chat' : 'Sharing...'}
          </button>
        )}
      </div>
      {/* <div
  className={`max-w-[1300px] mx-auto pb-[10rem] flex flex-col ${
    messages.length === 0 ? "h-full" : ""
  } lg:min-w-[1300px]`}
> */}
      <div
        className={`mx-auto pb-[13rem] flex flex-col ${
          messages.length === 0 ? 'h-full' : ''
        } w-full  max-w-[850px]`}
      >
        {messages?.length !== 0 ? (
          <>
            <div className="px-5 pt-5 flex flex-col justify-start gap-6">
              {messages?.map((chat, index) => {
                return (
                  <div key={index} className="flex flex-col gap-4">
                    {/* Show text content first */}
                    {chat.role === 'user' ? (
                      <QuestionContainer content={chat.content} files={chat.files || []} />
                    ) : (
                      chat.content &&
                      !chat.imageURL &&
                      !chat.imageKey && (
                        <GPTResponseContainer
                          content={chat.content}
                          externalImages={chat.externalImages ?? []}
                        />
                      )
                    )}

                    {/* Show multiple images from imageUrl if available */}
                    {Array.isArray(chat.imageUrl) &&
                      chat.imageUrl.length > 0 &&
                      chat.imageUrl.map((url, imgIndex) => (
                        <GPTResponseImage key={`${index}-url-${imgIndex}`} imageURL={url} />
                      ))}

                    {/* Show a single image from imageKey if available */}
                    {chat.imageKey && (
                      <GPTResponseImage key={`${index}-key`} imageKey={chat.imageKey} />
                    )}
                  </div>
                );
              })}
            </div>

            <div className="pb-5" ref={bottomRef} />
          </>
        ) : (
          <NewChat />
        )}
      </div>

      <div className="absolute bottom-0 pb-4 w-[100%] lg:w-[85%] bg-componentBg left-1/2 transform -translate-x-1/2">
        {/* {GPTLoading && <StopGenerating />} */}
        {currentChat !== '' && <PromptInput handleSubmit={handleSubmit} />}
      </div>
    </div>
  );
};

export default Conversation;
