import React from 'react';

const NewChat = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-1 md:gap-4 text-textColor bg-componentBg h-full rounded-3xl">
      <div className="text-center flex flex-col gap-2 md:gap-4 p-2">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold">
          Welcome to{' '}
          <span
            className="border-s-4 border-primary"
            style={{
              background: 'linear-gradient(90deg, #32666F 0%, rgba(32, 253, 201, 0.00) 100%)',
            }}
          >
            ChadGPT
          </span>
        </h1>
        <p className="lg:text-lg">Where Ideas Bloom</p>
      </div>

      <div className="md:mt-4 flex flex-col lg:flex-row items-center justify-center gap-10 p-2">
        <div className="flex flex-col justify-center items-center text-center gap-1 md:gap-2">
          <span>
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8868 18.7754L14.8845 18.7791C14.5113 19.374 14.2054 19.9579 13.9698 20.5303C13.2602 18.7977 11.9096 16.9666 9.87099 15.4374C9.02213 14.7971 8.14884 14.2865 7.26218 13.9218C9.42082 13.0514 11.419 11.481 12.7937 9.48698C13.2773 8.78846 13.6708 8.08414 13.9666 7.36219C14.7739 9.33887 16.3528 11.2596 18.293 12.6327C19.0484 13.1701 19.8312 13.6077 20.6362 13.9342C18.3762 14.8905 16.1308 16.8123 14.8868 18.7754Z"
                stroke="currentColor"
                stroke-width="1.95"
              />
              <path
                d="M5.24867 7.38143L5.24713 7.38388C5.09913 7.61974 4.97002 7.85322 4.86066 8.08418C4.4467 7.20912 3.74116 6.31067 2.72855 5.55104C2.34853 5.26442 1.95835 5.02439 1.56128 4.83648C2.64357 4.34196 3.63276 3.52926 4.33003 2.51795C4.53627 2.22 4.71329 1.91914 4.8586 1.61239C5.32907 2.60753 6.14102 3.55265 7.11598 4.24266C7.44811 4.47892 7.79063 4.68048 8.14208 4.84381C6.99032 5.39899 5.88329 6.37993 5.24867 7.38143Z"
                stroke="currentColor"
                stroke-width="1.3"
              />
            </svg>
          </span>
          <h3 className="text-xl font-semibold lg:mx-4">Easy</h3>
          <p className="mx-auto text-center hidden">
            Pariatur sint laborum cillum aute consectetur irure.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center text-center gap-1 md:gap-2">
          <span>
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.9995 9.50008C10.4211 9.50008 9.49946 10.4217 9.49946 13.0001C9.49946 15.5784 10.421 16.5001 12.9994 16.5001C15.5777 16.5001 16.4995 15.5784 16.4995 13.0001C16.4995 10.4217 15.5778 9.50008 12.9995 9.50008ZM23.4294 11.8334C22.8927 6.96841 19.031 3.10675 14.166 2.57008V0.166748H11.8327V2.57008C6.96768 3.10675 3.10602 6.96841 2.56935 11.8334H0.166016V14.1667H2.56935C3.10602 19.0317 6.96768 22.8934 11.8327 23.4301V25.8334H14.166V23.4301C19.031 22.8934 22.8927 19.0317 23.4294 14.1667H25.8327V11.8334H23.4294ZM12.9994 21.1667C8.48435 21.1667 4.83268 17.5151 4.83268 13.0001C4.83268 8.48508 8.48435 4.83342 12.9994 4.83342C17.5144 4.83342 21.166 8.48508 21.166 13.0001C21.166 17.5151 17.5144 21.1667 12.9994 21.1667Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <h3 className="text-xl font-semibold lg:mx-4">Precise</h3>
          <p className="mx-auto text-center hidden">
            Pariatur sint laborum cillum aute consectetur irure.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center text-center gap-1 md:gap-2">
          <span>
            <svg
              width="26"
              height="26"
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <h3 className="text-xl font-semibold lg:mx-4">Efficient</h3>
          <p className="mx-auto text-center hidden">
            Pariatur sint laborum cillum aute consectetur irure.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewChat;
